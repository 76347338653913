<template>
  <v-layout
    class="align-center text-xs-center"
  >
    <v-flex
      class="mx-auto"
    >
      <v-progress-circular
        :color="status.color"
        :width="3"
        :size="90"
        indeterminate
      >
        <v-icon
          class="font-size-80 top-1 position-relative"
          :color="status.color"
        >
          {{ status.icon }}
        </v-icon>
      </v-progress-circular>
    </v-flex>
  </v-layout>
</template>

<script>
  import find from '@/plugins/mixins/keyfind'

  export default {
    mixins: [
      find
    ],

    data: function () {
      return {
        status: {
          color: 'success',
          icon: 'check_circle_outline'
        }
      }
    },

    mounted () {
      if (this.keyFind(this.$route, 'sale')) {
        window.parent.dispatchEvent(
          new CustomEvent(
            'success',
            {
              detail: {
                sale: this.$route.query.sale
              }
            }
          )
        )
      } else {
        this.status.color = 'error'
        this.status.icon = 'error'

        window.parent.dispatchEvent(
          new CustomEvent(
            'error',
            {
              detail: {
                error: true
              }
            }
          )
        )
      }
    }
  }
</script>
