<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Claro tv pré-pago
        </v-card-title>

        <v-card-text
          class="font-size-18"
        >
          Desculpe!
          <v-spacer
            class="my-1"
          />
          Este pedido não foi localizado em nosso sistema.
          <v-spacer
            class="my-3"
          />
          Por favor, efetue um novo pedido.
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red"
            flat
            @click="$router.push('/')"
          >
            Novo pedido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import getProduct from '@/plugins/mixins/product'

  export default {
    mixins: [
      getProduct
    ],

    data: function () {
      return {
        dialog: false
      }
    },

    mounted () {
      this.Protocolo(this.$route.params.protocolo).then(response => {
        if (response.success) {
          this.openFormData(response.payload)
          return
        }
        this.dialog = true
      })
    },

    methods: {
      ...mapActions('Payment', [
        'Protocolo'
      ]),
      ...mapActions('Payload', [
        'OpenPagamento'
      ]),

      openFormData (payload) {
        this.OpenPagamento(payload)

        this.dataLayer(payload)
      },

      dataLayer (payload) {
        window.dataLayer.push({
          'event': 'addToCart',
          'eventCategory': 'claro:tv-pre',
          'eventAction': 'enhanced-ecommerce',
          'eventLabel': `card:contratar:${JSON.parse(payload).pedido.produto[0].id}`,
          'ecommerce': {
            'ec': 'setAction',
            'add': {
              'products': [{
                'name': String(this.getProduct(JSON.parse(payload).pedido.produto[0].id, 'title').nome).replace(/ /g, '').toLowerCase(),
                'id': String(this.getProduct(JSON.parse(payload).pedido.produto[0].id, 'plan')).replace(/ /g, ''),
                'price': String(this.getProduct(JSON.parse(payload).pedido.produto[0].id, 'price').default).replace(/,|\./g, '.'),
                'brand': 'meu-plano-claro',
                'category': 'tvpre',
                'quantity': '1'
              }]
            }
          }
        })
      }
    }
  }
</script>
